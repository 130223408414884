import React from "react";
function ProjectThumb({ title, desc, titleSize }) {
  return (
    <div className="project">
      <div className="desc-bg"></div>
      <div className="desc-cont">
        <div className="name" style={{ fontSize: titleSize }}>
          {title}
        </div>
        <div className="desc">{desc}</div>
      </div>
      <div className="img-fade"></div>
      <div
        className="img-cont"
        style={{
          backgroundImage: `url('/img/${title
            .toLowerCase()
            .replace(".", "")}.png')`,
          backgroundPosition: "center"
        }}
      ></div>
    </div>
  );
}

export default ProjectThumb;
