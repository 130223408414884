import React, { useState, useEffect } from "react";
import ScrollableSection from "react-update-url-on-scroll";
import "./css/Portfolio.scss";
import Header from "./Header";
import About from "./About";
import Projects from "./Projects";
import HomeSolid from "../home-solid.svg";

function Portfolio() {
  let [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
  });

  const scrollHandler = e => {
    const scrolleddown = window.scrollY > 0;
    if (scrolled !== scrolleddown) setScrolled(scrolleddown);
  };

  return (
    <div className={`Portfolio ${scrolled ? "fixed" : ""}`}>
      <Header />
      <ScrollableSection hash={"about"}>
        <div>
          <About />
        </div>
      </ScrollableSection>
      <div className="divider" />
      <ScrollableSection hash={"work"}>
        <Projects />
      </ScrollableSection>
      <div className="divider" />
      <ScrollableSection hash={"contact"}>
        <div className="container">
          <h2>Contact</h2>
          <h3 style={{ marginTop: 10 }}>Michael van Eerd</h3>
          <h3 style={{ marginTop: 2 }}>
            <a
              className="ahref"
              href="mailto:michael@cache.works"
              target="_blank"
              rel="noopener noreferrer"
            >
              michael@cache.works
            </a>
          </h3>
          <img
            src={HomeSolid}
            width={20}
            alt="location"
            style={{ float: "left", marginRight: 5, marginTop: 4 }}
          />
          <h4 style={{ marginTop: 4 }}>Eindhoven, NL</h4>
        </div>
      </ScrollableSection>
      <div className="footer"></div>
    </div>
  );
}

export default Portfolio;
