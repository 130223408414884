import React from "react";
import ProjectThumb from "./ProjectThumb";
import DevLabel from "./DevLabel";

function Projects() {
  return (
    <>
      <div className="container">
        <h2>Projects</h2>
        <div className="devlabels">
          <DevLabel text="React" /> <DevLabel text="php" />{" "}
          <DevLabel text="HTML5" /> <DevLabel text="CSS3" />{" "}
          <DevLabel text="Javascript" /> <DevLabel text="Node.js" />{" "}
          <DevLabel text="Go" /> <DevLabel text="WebRTC" />{" "}
          <DevLabel text="Backend" /> <DevLabel text="Magento" />
        </div>
        <div className="projects">
          <ProjectThumb
            title="byte.chat"
            desc={
              <>
                WebRTC video chat
                <br />
                <br />
                Progressive web app
                <br />
                <br />
                Logo design
              </>
            }
          />

          <ProjectThumb
            title="cyclewear.eu"
            desc={
              <>
                Magento webshop configuration
                <br />
                <br />
                Custom extensions and migration
                <br />
                <br />
                Google shopping feed
              </>
            }
            titleSize={19}
          />

          <ProjectThumb
            title="synchrotu.be"
            desc={
              <>
                Youtube player sync
                <br />
                <br />
                Websockets
                <br />
                <br />
                Logo design
              </>
            }
            titleSize={19}
          />

          <ProjectThumb
            title="WATechSales"
            desc={
              <>
                Site layout
                <br />
                <br />
                Backend
                <br />
                <br />
                Web hosting
              </>
            }
            titleSize={17}
          />

          <ProjectThumb title="cache.works" desc="" titleSize={19} />

          <ProjectThumb title="ENDURR" desc="Logo design" />

          <ProjectThumb title="Obins" desc="Magento webshop" />

          <ProjectThumb
            title="Essentra"
            desc={
              <>
                Webshop maintenance
                <br />
                <br />
                Google Adwords
                <br />
                <br />
                SEO
              </>
            }
          />

          <ProjectThumb
            title="APE.Industries"
            desc={
              <>
                Magento webshop
                <br />
                <br />
                Server management
              </>
            }
            titleSize={17}
          />

          <ProjectThumb
            title="WervingenAdvies"
            desc={<>Custom content management</>}
            titleSize={14}
          />
        </div>
      </div>
    </>
  );
}

export default Projects;
